<template>
    <!-- start inner banner  -->
    <section class="inner-banner">

        <div class="row row-title-bar mt-0">
            <div class="col-xs-12 col-md-6 col-lg-6 title"><h4 class="page-title mt-0">My Vacancies</h4></div>
            <div class="col-xs-12 col-md-6 col-lg-6 breadcrumb-section">
            </div>
        </div>

        <hr>
        <!-- start inner page content area  -->

        <div class="card m-4" v-for="vacancy in vacancies" :key="vacancy.id">
            <div class="card-body">
                <h5 class="card-title">{{ vacancy.title }}</h5>
                <p class="card-text">{{ vacancy.description }}</p>
                <p>created: {{ vacancy.created_at }}</p>
                <p>last updated: {{ vacancy.updated_at }}</p>
                <div class="text-right">
                    <router-link :to="`/vacancies/${slugify(vacancy.title)}-${vacancy.id}/edit`" tag="button"
                                 class="btn btn-warning" v-if="can('can_update_vacancies')">Edit
                    </router-link>
                    <button class="btn btn-danger" @click="deleteVacancy(vacancy)" v-if="can('can_delete_vacancies')">
                        Delete
                    </button>
                    <button v-if="vacancy.is_public && can('can_update_vacancies')" class="btn btn-secondary"
                            @click="unPublishVacancy(vacancy)">Un-Publish
                    </button>
                    <button v-if="!vacancy.is_public && can('can_update_vacancies')" class="btn btn-primary"
                            @click="publishVacancy(vacancy)">Publish
                    </button>
                    <router-link :to="`/vacancies/${slugify(vacancy.title)}-${vacancy.id}`" tag="button"
                                 class="btn btn-success">Open
                    </router-link>
                </div>
            </div>
        </div>

    </section>
</template>

<script>
import {myVacancyService} from "@/services/myVacancyService";
import slugify from "@/js/slugify";

export default {
    name: "MyVacancies",
    data() {
        return {
            slugify: slugify
        }
    },
    computed: {
        vacancies() {
            return this.$store.state.vacancies.myVacancies
        },
    },
    created() {
        this.getVacancies()
    },
    methods: {
        getVacancies() {
            myVacancyService.index().then(response => {
                this.$store.commit('setMyVacancies', response.data.data)
            })
        },
        unPublishVacancy(vacancy) {
            let areYouSure = confirm('Are you sure?')

            if (areYouSure) {
                myVacancyService.changeStatus(vacancy.id,{is_public: false} ).then(() => vacancy.is_public = 0)
            }
        },
        publishVacancy(vacancy) {
            let areYouSure = confirm('Are you sure?')

            if (areYouSure) {
                myVacancyService.changeStatus(vacancy.id,{is_public: false} ).then(() => vacancy.is_public = 1)
            }
        },
        deleteVacancy(vacancy) {
            let areYouSure = confirm('Are you sure?')

            if (areYouSure) {
                myVacancyService.deleteVacancy(id).then(() => this.$store.commit('deleteMyVacancy', vacancy))
            }
        }
    },
}
</script>

<style scoped>
.btn {
    line-height: 17px;
    border-radius: 50px;
    padding: 15px 25px;
    box-shadow: none;
    border-color: unset;
    color: white;
    font-weight: bold;
}
</style>